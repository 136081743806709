<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        outlined
        hide-details
        style="margin-bottom: 35px;"
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-weight: bold"
              >Filter</v-expansion-panel-header
            >
            <v-expansion-panel-content style="margin-top: 30px">
              <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-menu
                    v-model="datePickerMenu1"
                    :close-on-content-click="false"
                    max-width="400px"
                    min-width="auto"
                    class="mx-sm-1"
                    offset-y
                    transition="scale-transition"
                    origin="center center"
                    ref="datePickerMenu1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate1"
                        clearable
                        label="Startdatum"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date1 = null"
                        required
                        outlined
                        class="mx-sm-1"
                        append-icon="mdi-calendar"
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" color="primary">
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="secondary"
                        @click="datePickerMenu1 = false"
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.datePickerMenu1.save(date1)"
                      >
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-menu
                    v-model="datePickerMenu2"
                    :close-on-content-click="false"
                    max-width="290"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                    origin="center center"
                    ref="datePickerMenu2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate2"
                        clearable
                        label="Enddatum"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date2 = null"
                        required
                        outlined
                        class="mx-sm-1"
                        append-icon="mdi-calendar"
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" color="primary">
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="secondary"
                        @click="datePickerMenu2 = false"
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.datePickerMenu2.save(date2)"
                      >
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    label="Betreff"
                    required
                    v-model="selectedSubject"
                    :items="availableSubjects"
                    outlined
                    class="mx-sm-1"
                    transition="scale-transition"
                    origin="center center"
                    item-text="description"
                    :item-value="item => item"
                    return-object
                    clearable
                    small-chips
                    solo
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    label="Erfassung"
                    required
                    v-model="selectedCapture"
                    outlined
                    :items="dialogInputMethods"
                    class="mx-sm-1"
                    transition="scale-transition"
                    origin="center center"
                    return-object
                    clearable
                    small-chips
                    solo
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="" lg="" xl="">
                  <v-tooltip top color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        @click="resetFilter()"
                        class="white--text mx-sm-1 "
                        style="float: right;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <span>Filter löschen</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="interactionsFiltered"
      :items-per-page="10"
      :search="search"
      item-key="aDat"
      :expanded.sync="expanded"
      show-expand
      :single-expand="singleExpand"
      class="elevation-1"
      :loading="loading"
      sort-by="aDat"
      :sort-desc="sortDesc"
      mobile-breakpoint="800"
      style="margin-top: -30px"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" persistent max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="newInteraction"
              >
                Neue Interaktion
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="mx-0 px-0 my-0 py-0">
                <v-toolbar
                  color="primary"
                  class="mx-0 px-0 my-0 py-0"
                  fixed
                  top
                  dark
                >
                  <v-icon @click="close()">mdi-close</v-icon>
                  <v-toolbar-title class="flex text-center">{{
                    formTitle
                  }}</v-toolbar-title>
                </v-toolbar>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row class="pt-4">
                    <v-col class="pa-0 pr-1" cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.aDatF"
                        label="Erstellungsdatum"
                        outlined
                        disabled
                        v-if="!isNewInteraction"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 pl-md-1" cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.mDatF"
                        label="Zuletzt bearbeitet"
                        outlined
                        disabled
                        v-if="!isNewInteraction"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 pl-md-2" cols="12" md="4">
                      <v-text-field
                        label="Dokument"
                        outlined
                        disabled
                        v-model="editedItem.documentName"
                        v-if="!isNewInteraction"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 pr-1" cols="12" sm="6" md="6">
                      <v-select
                        :items="dialogInputMethods"
                        label="Erfassung"
                        v-model="editedItem.capture"
                        outlined
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col class="pa-0 pl-md-1" cols="12" sm="6" md="6">
                      <v-select
                        label="Betreff"
                        clearable
                        outlined
                        v-model="editedItem.subject"
                        :items="availableSubjects"
                        item-text="description"
                        return-object
                      ></v-select>
                    </v-col>

                    <v-col class="pa-0" cols="12">
                      <v-text-field
                        v-model="editedItem.keywords"
                        label="Stichworte"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0" cols="12">
                      <RichTextEditor
                        ref="richTextEditor"
                        :text="editedItem.text"
                        editable
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="save"> Speichern </v-btn>
                <v-btn color="error" text @click="close"> Abbrechen </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <WarningDialog
            ref="warning"
            @warningActionPerformed="warningActionPerformed"
          />

          <v-dialog v-model="dialogDelete" width="650">
            <v-card>
              <v-toolbar color="warning" dark>
                <v-icon @click="closeWarningDialog()">close</v-icon>
                <v-toolbar-title class="flex text-center">
                  Warnung
                </v-toolbar-title>
              </v-toolbar>
              <v-container class="pa-4">
                <v-row class="mx-2">
                  <v-col cols="12">
                    Wollen Sie die Interaktion
                    <strong>"{{ editedItem.keywords }}"</strong> wirklich
                    löschen?
                    <v-card-text></v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error lighten-1 px-8"
                        @click="deleteItemConfirm()"
                        >LÖSCHEN</v-btn
                      >
                      <v-btn color="black" text @click="closeWarningDialog()"
                        >Abbrechen</v-btn
                      >
                    </v-card-actions>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="d-flex flex-column align-end"
                    cols="colsPerButton"
                    v-for="action in actions"
                    :key="action.action"
                  >
                    <v-btn
                      outlined
                      class="warning--text"
                      @click="warningActionPerformed(action.action)"
                    >
                      <v-icon class="mr-1">{{ action.icon }}</v-icon>
                      {{ action.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.documentName="{ item }">
        <router-link
          v-if="item.wf_cid"
          :to="{
            name: 'crmAddressDocumentDetailed',
            params: { lfdnr: lfdnr, documentId: item.wf_cid }
          }"
        >
          <span>{{ item.documentName }}</span>
        </router-link>
      </template>

      <template v-slot:item.subject="{ item }">
        {{
          item == null ||
          item.subject == null ||
          item.subject.description == null
            ? '-'
            : item.subject.description
        }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="white px-4 py-2"
          :colspan="headers.length"
          v-if="item.text || item.wf_cid"
        >
          <div v-if="item.text">
            <h3>Text:</h3>
            <p v-html="item.text"></p>
          </div>

          <div v-if="item.wf_cid">
            <h3>Dokument:</h3>
            <router-link
              :to="{
                name: 'crmAddressDocumentDetailed',
                params: { lfdnr: lfdnr, wf_cid: item.wf_cid }
              }"
            >
              <span>{{ item.documentName }}</span>
            </router-link>
          </div>
        </td>
        <td class="white px-4 py-2" :colspan="headers.length" v-else>
          Keine Daten vorhanden.
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          fab
          small
          text
          @click="editInteraction(item)"
          elevation="2"
          class="mr-2 col-xl-5"
        >
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>

        <v-btn
          fab
          small
          text
          color="red"
          @click="deleteInteraction(item)"
          elevation="2"
          class="mr-2 col-xl-5"
        >
          <v-icon small>delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data> Keine Interaktionen vorhanden </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import WarningDialog from '@/components/core/WarningDialog.vue'
import moment from 'moment'
import RichTextEditor from '@/components/core/RichTextEditor.vue'

// https://techformist.com/vuetify-input-field-date-picker/
// filters: https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component

export default {
  components: {
    WarningDialog,
    RichTextEditor
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      expanded: [],
      singleExpand: false,
      sortDesc: true,
      editorContent: '',
      dialogInputMethods: ['Manuell', 'E-Mail', 'Telefon'],
      headers: [
        {
          text: 'Erstellungsdatum',
          align: 'start',
          value: 'aDatF'
        },
        {
          text: 'Zuletzt bearbeitet',
          align: 'start',
          value: 'mDatF'
        },
        { text: 'Betreff', value: 'subject' },
        { text: 'Erfassung', value: 'capture' },
        { text: 'Stichworte', value: 'keywords' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '12em'
        }
      ],

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        mDatF: '',
        subject: '',
        capture: '',
        keywords: '',
        text: '',
        documentName: '',
        wf_cid: ''
      },

      datePickerMenu1: false,
      datePickerMenu2: false,
      //textFieldInput: '',
      inputRequirements: [
        inputValue => !!inputValue || 'Feld ausfüllen',
        inputValue =>
          (inputValue && inputValue.length >= 2) || 'Min. 2 Buchstaben'
      ],
      inputRequirements2: [
        dateInputValue => !!dateInputValue || 'Feld ausfüllen'
      ],
      betreffSelectorItems: ['item1', 'item2', 'item3', 'item4'],
      erfassungSelectorItems: ['item1', 'item2', 'item3'],
      date: null,
      date1: '',
      date2: '',
      selectedSubject: null,
      warningDialog: false,
      actions: [],
      selectedCapture: null,
      allSubject: [], //alle Betreffe (:items)
      allCapture: [] //alle Erfassungen (:items)
    }
  },
  computed: {
    ...mapState({
      interactions: state => state.crmInteractions.interactions,
      availableSubjects: state => state.crmInteractions.availableSubjects
    }),
    formTitle() {
      return this.editedIndex === -1
        ? 'Neue Interaktion'
        : 'Interaktion bearbeiten'
    },
    isNewInteraction() {
      if (this.editedIndex == -1) {
        return true
      }
      return false
    },
    isDocumentInteractions() {
      return this.wf_cid != null && this.wf_cid != ''
    },
    formattedDate1() {
      moment.locale()
      return this.date1 ? moment(this.date1).format('ll') : ''
    },
    formattedDate2() {
      moment.locale()
      return this.date2 ? moment(this.date2).format('ll') : ''
    },
    interactionsFiltered() {
      let filteredInteractions = this.interactions.filter(item => {
        let isStartDateValid =
          this.date1 == null || this.date1 === '' || item.aDat > this.date1
        let isEndDateValid =
          this.date2 == null || this.date2 === '' || item.aDat < this.date2
        let isSubjectValid =
          this.selectedSubject == null ||
          item.subject?.code === this.selectedSubject.code
        let isCaptureValid =
          this.selectedCapture == null || item.capture === this.selectedCapture

        return (
          isStartDateValid && isEndDateValid && isSubjectValid && isCaptureValid
        )
      })

      let beginDateFormat = new Date(this.date1)
      let endDateFormat = new Date(this.date2)

      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum')
      }

      return filteredInteractions
    }
  },
  methods: {
    init() {
      this.$store.dispatch('loadAvailableSubjects', { wf_cid: this.wf_cid })
      this.loadInteractions()
      this.fillFilter()
    },
    loadInteractions() {
      this.$store
        .dispatch('loadInteractions', {
          lfdnr: this.lfdnr,
          wf_cid: this.wf_cid
        })
        .then((this.loading = false))
    },
    closeWarningDialog() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    open(warnMessage, actions, additionalData = []) {
      this.warnMessage = warnMessage
      this.actions = actions
      this.warningDialog = true
      this.colsPerButton = Math.floor(12 / actions.length)
      this.additionalData = additionalData
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action)
      this.warningDialog = false
    },

    newInteraction() {
      this.$store.dispatch('loadAvailableSubjects', { wf_cid: this.wf_cid })
    },
    editInteraction(item) {
      this.$store.dispatch('loadAvailableSubjects', { wf_cid: this.wf_cid })
      this.editedIndex = this.interactions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteInteraction(item) {
      this.editedIndex = this.interactions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.dispatch('deleteInteraction', {
        lfdnr: this.lfdnr,
        wf_cid: this.wf_cid,
        interaction: this.editedItem
      })

      this.closeDelete()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      this.editedItem.text = this.$refs.richTextEditor.editor.getHTML()
      if (this.editedIndex > -1) {
        this.$store.dispatch('editInteraction', {
          lfdnr: this.lfdnr,
          wf_cid: this.wf_cid,
          interaction: this.editedItem
        })
      } else {
        this.editedItem.wf_cid = this.wf_cid
        this.$store
          .dispatch('addInteraction', {
            lfdnr: this.lfdnr,
            wf_cid: this.wf_cid,
            interaction: this.editedItem
          })
          .then(() => {
            this.editedItem = this.defaultItem
            this.editedIndex = -1
            this.loadInteractions()
          })
      }
      this.close()
    },
    resetFilter() {
      this.date1 = null
      this.date2 = null
      this.selectedSubject = null
      this.selectedCapture = null
    },
    fillFilter() {
      if (this.date1 == null || '') {
        this.date1 = ''
      }
      if (this.date2 == null || '') {
        this.date2 = ''
      }

      this.availableSubjects.forEach(elem => {
        this.allSubject.push(elem.selectedSubject)
        this.allCapture.push(elem.selectedCapture)
      })
    }
    //getExternalData() {}
  },
  watch: {
    lfdnr() {
      this.init()
    },
    wf_cid() {
      this.init()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style></style>
